<template>
  <el-form
    :model="districtsList"
    ref="formedForm"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="10">
      <el-col :span="8">
          <el-select
            clearable
            class="searchPole"
            v-model="search.waste"
            filterable
            remote
            value-key="ss01DictionaryId"
            reserve-keyword
            placeholder="Наименование"
          >
            <el-option
              v-for="item in wasteNames"
              :key="item.ss01WasteDictionaryDTO.ss01DictionaryId"
              :label="item.ss01WasteDictionaryDTO.wasteName"
              :value="item.ss01WasteDictionaryDTO"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="8">
          <el-select
            clearable
            class="searchPole"
            v-model="search.waste"
            filterable
            remote
            value-key="ss01DictionaryId"
            reserve-keyword
            placeholder="Код отхода"
          >
            <el-option
              v-for="item in wasteNames"
              :key="item.ss01WasteDictionaryDTO.ss01DictionaryId"
              :label="item.ss01WasteDictionaryDTO.wasteCode"
              :value="item.ss01WasteDictionaryDTO"
            >
            </el-option>
          </el-select>
        </el-col>
    </el-row>

    <el-row>
      <el-col :span="6">
        <el-button type="primary" @click='addWasteToTable'>Добавить отход</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
//import SearchBlock from "@/components/searchBlock";
import { mapGetters } from "vuex";
//import CustomModal from "@/components/customModal";
//import FormedModalUpdate from "@/components/ecologist/dataEntry/modals/update/formedModalUpdate";
//import notification from "@/mixins/notification";

export default {
  props: ["action"],
  name: "disposalAddWasteForm",
  //components: { FormedModalUpdate, CustomModal, SearchBlock },
  data() {
    return {
      branchesList: [],
      subDivisionsList: [],
      districtsList: null,
      search: {
        dateFrom: null,
        dateTo: null,
        waste: null,
        wasteCode: null,
        branch: null,
        subDivision: null,
        district: null,
      },
      selectedAction: {
        actionInfo: {
          id: null,
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      wasteNames: "GETLISTWASTEPRODUCTION",
    }),
  },

  methods: {
    addWasteToTable () {
      if(this.search.waste != null)
      {
        console.log(this.search.waste);
      this.$parent.$parent.$parent.addWasteToTable(this.search.waste);
      this.$store.dispatch("setShowModal", null);
      }
    },

    searchWasteName(query) {
      this.getWasteNames(query);
    },

    async getWasteNames() {
      await this.$store.dispatch("getOrganizationWastesListByOrganizationIdAndDate",this.action);
    },
  },

  mounted() {
    console.log("this");
    console.log(this);
    this.getWasteNames("");
  },
};
</script>

<style scoped></style>
