<template>
  <div>
    <disposal-main></disposal-main>
  </div>
</template>

<script>
import DisposalMain from "@/components/ecologist/disposal/disposalMain";
export default {
  name: "disposalMainView",
  components: { DisposalMain },
};
</script>

<style scoped></style>
